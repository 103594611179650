<template>
<div>
  <div class="register">
    <div class="reg-box">
      <div class="register-logo clearfix">
        <div class="bx-box">
          <div class="logo-pic">
            <img src="@/assets/image/login/zc1.png">
          </div>
          <div class="logo-tit">中科可控供需协同平台</div>
        </div>
      </div>
      <div class="register-cont register-cont-btn">
        <div class="register-tit clearfix">
          <span class="left-span"><span class="res-line"></span>注册</span>
          <span class="right-span" @click="GoLogin"><span>有账号，</span>去登录></span>
        </div>
        <div class="register-news">
          <el-form :model="passWordRuleForm" :rules="passWordNewsRules" ref="passWordRuleForm" class="demo-ruleForm">
            <el-form-item label="用户名" :class="{styleName: isStyleregisterName}" prop="username">
              <el-input v-model="passWordRuleForm.username" maxlength="30" @focus="NameFocus" @blur="NameBlur" placeholder="请输入用户名"></el-input>
              <div class="tsinput1" v-show="ShowInput1">请录入长度为4~20位的数字+字母组合</div>
            </el-form-item>
            <el-form-item class="code-pos" label="密码" :class="{styleName: isStyleRegisterPass}" prop="password">
              <el-input type="password" v-model="passWordRuleForm.password" maxlength="16" @focus="PassFocus" @blur="PassBlur" placeholder="请输入密码" show-password></el-input>
              <div class="tsinput1" v-show="ShowInput2">请录入长度为4~20位的数字+字母组合</div>
            </el-form-item>
            <div class="yz-flot">
              <el-form-item class="ver-left yz-ver-left" prop="verification">
                <el-select v-model="passWordRuleForm.verification" placeholder="请选择验证方式">
                  <el-option label="短信验证" value="短信验证"></el-option>
                  <el-option label="邮箱验证" value="邮箱验证"></el-option>
                </el-select>
                <div class="tsinput1 oth-tsinput1" v-show="ShowInput3">短信验证和邮箱验证任选其一即可，可做为登录账号</div>
              </el-form-item>
              <el-form-item v-if="passWordRuleForm.verification == '短信验证'" class="yz-user-phong" :class="{styleName: isStyleRegisterPhone}" prop="phone">
                <el-input @focus="RegFocus" @blur="RegBlur" v-model="passWordRuleForm.phone" maxlength="18" placeholder="请输入手机号"></el-input>
              </el-form-item>
              <el-form-item v-else-if="passWordRuleForm.verification == '邮箱验证'" class="yz-user-phong" :class="{styleName: isStyleRegisterMail}" prop="mail">
                <el-input @focus="RegMailFocus" @blur="RegMailBlur" v-model="passWordRuleForm.mail" maxlength="30" placeholder="请输入邮箱"></el-input>
              </el-form-item>
            </div>
            <el-form-item class="code-pos user-phong mb-code-pos" label="验证码" prop="verificationCode" :class="{styleName: isStyleRegisterCode}">
              <el-input v-model="passWordRuleForm.verificationCode" @focus="CodeFocus" @blur="CodeBlur" placeholder="请输入验证码"></el-input>
              <el-button class="get-code" v-if="passWordRuleForm.verification == '短信验证'" @click="getCode" :disabled="codeDisabled" type="primary" plain>{{ btnTxt }}</el-button>
              <el-button class="get-code" v-else-if="passWordRuleForm.verification == '邮箱验证'" @click="getEmail" :disabled="emailDisabled" type="primary" plain>{{ btnTxt }}</el-button>
              <div class="tsinput1" v-show="ShowInput4">请输入短信或邮箱收到的验证码</div>
            </el-form-item>
            <el-form-item class="check-box">
              <el-checkbox-group v-model="passWordRuleForm.agreement">
                <el-checkbox v-for="city in cities" :label="city" :key="city" @click = "GetCheckBok" name="agreement"></el-checkbox>
              </el-checkbox-group>
              <span class="check-span" @click="clauseVistabe = true">我已阅读并同意用户协议</span>
            </el-form-item>
            <el-button class="subbtn" :disabled="btnDisabled" type="primary" @click="RegisterSubmit('passWordRuleForm')">立即注册</el-button>
          </el-form>
        </div>
      </div>
      <div class="login-footer">
        <div>版权所有 © 2001-2018 中科可控信息产业有限公司  All rights reserved.
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">苏ICP备18044399号-1</a>
        </div>
      </div>
    </div>
  </div>
  <!-- <div>注册成功3秒后跳转登录页</div> -->
  <div class="clause-box">
    <el-dialog
      title="用户服务协议："
      v-model="clauseVistabe"
      width="1100px"
    >
      <div class="registrTextTit">重要须知</div>
        <div class="registrText">
          中科可控信息产业有限公司在此特别提醒用户认真阅读本《软件用户服务协议》--- 用户应认真阅读本《软件用户服务协议》 （下称《协议》）中各条款，尤其应仔细阅读协议中以粗体标识的条款，此类条款将免除中科可控信息产业有限公司部分责任或限制用户的权利。用户承诺具有完全民事行为能力（无民事行为能力人或限制行为能力人已获得监护人同意）或合法成立的法人主体（操作人员已获得法人授权）。用户接受本《协议》或使用软件行为，视为用户完全理解并接受本《协议》内容，同意接受本《协议》各项条款的约束。如用户不接受本《协议》条款，则无权下载、安装或使用本“软件”及其相关服务。
        </div>
      <div class="registrText">
        本《协议》是用户与中科可控信息产业有限公司公司（下称中科可控）之间关于用户下载、安装、使用、复制中科可控 软件, （以下简称“软件”）所订立的协议。本《协议》描述中科可控与用户之间关于“软件”许可使用及相关方面的权利义务。“用户”或“您”是指通过中科可控提供的获取软件授权的途径获得软件授权许可和/或软件产品的个人或法人实体。
      </div>
      <div class="registrTextTit">1.权利声明</div>
      <div class="registrText">
        本“软件”由中科可控公司开发。本“软件”的一切版权等知识产权，以及与“软件”相关的所有信息内容，包括但不限于：文字表述及其组合、图标、图饰、图表、色彩、界面设计、版面框架、有关数据、印刷材料、或电子文档等均为中科可控所有，受著作权法和国际著作权条约以及其他知识产权法律法规的保护。
      </div>
      <div class="registrTextTit">2.用户使用许可授权范围</div>
      <div>
        <div class="registrText">
          2.1用户可以在单一计算机（下统称"计算机"）上安装、使用、显示、运行本“软件”。
        </div>
        <div class="registrText">
          2.2保留权利： 本《协议》未明示授权的其他一切权利仍归中科可控所有， 用户使用其他权利时须另外取得中科可控的书面同意。
        </div>
        <div class="registrText">
          2.3除本《协议》有明确规定外，本《协议》并未对利用本“软件”访问的中科可控的其他服务规定相关的服务条款，对于这些服务可能有单独的服务条款加以规范，请用户在使用有关服务时另行了解与确认。如用户使用该服务，视为对相关服务条款的接受。
        </div>
      </div>
      <div class="registrTextTit">3.用户使用须知</div>
      <div>
        <div class="registrText">
          3.1中科可控供需协同平台管理系统<br>
          用户承诺向“软件”服务提交或发布的任何资料均为真实的、准确的、完整的、合法的、不侵犯任何第三方权益，包括数据、文本、软件、音乐、图片、录像或其他材料（以下称“您的资料”），并对上述您的资料进行实时更新。如您的资料侵犯第三方权利，由您自行承担相应法律责任。若导致中科可控承担责任的，本公司有权向您进行全额索赔。
        </div>
        <div class="registrText">
          用户使用本“软件”的服务时，遭受任何意外、硬盘损毁、合约毁坏、网络黑客、电脑病毒、软件维护、版权或知识产权侵犯均可能导致暂时或永久地无法使用本“软件”服务，由此所造成的损失由用户自行承担，中科可控不承担任何法律责任。
        </div>
        <div class="registrText">
          中科可控尊重他人的知识产权;您理解并同意，对于对他人享有知识产权的内容的下载和利用, 完全出于您自己的判断，由您自己承担风险, 中科可控对此不承担任何责任。
        </div>
        <div class="registrText">
          中科可控尊重并保护所有使用中科可控 供需协同平台管理系统的个人隐私权,为“软件”服务的正常使用需收集您的基本信息，但 中科可控不会主动地泄露给第三方。若您自愿地在“软件”服务中向第三方披露您的信息，则您知悉相关信息可能被第三方获取或使用，您自愿承担因自我披露造成的任何后果。
        </div>
        <div class="registrText">
          我们的知识产权政策是对符合《信息网络传播权保护条例》和其它适用的知识产权法律规定的声称侵权之通告进行回复。任何单位或个人认为通过中科可控在线服务获得的链接的下载可能涉嫌侵犯其合法权益的，应该及时向中科可控书面反馈，并请提供身份证明、权属证明及详细侵权情况证明，有关详细信息，请与我们联系。中科可控有权采取删除、屏蔽、断开链接、终止服务等必要措施。若因通知人错误造成其他用户损害的，通知人应自行承担法律责任，造成中科可控损失有权向错误通知人追偿。
        </div>
        <div class="registrText">
          如您不再继续使用本“软件”服务时，可直接注销账户。除相关法律规定外，中科可控将删除您的用户信息及所有数据，停止向您提供服务。您应在注销前自行进行数据备份并通知合作第三方。中科可控保留依据本《协议》追究您相关责任的权利。
        </div>
        <div class="registrText">
          3.2本“软件”不含有任何旨在破坏用户计算机数据和获取用户隐私信息的恶意代码，不含有任何跟踪、监视用户计算机和或操作行为的功能代码，不会监控用户网上、网下的行为或泄漏用户隐私。
        </div>
        <div class="registrText">
          3.3用户在遵守法律及本《协议》的前提下可依本《协议》使用本“软件”。用户无权实施包括但不限于下列行为：
        </div>
        <div class="registrText">
          3.3.1不得删除本“软件”及其他副本上所有关于版权的信息、内容；
        </div>
        <div class="registrText">
          3.3.2不得对本“软件”进行反向工程、反向汇编、反向编译等；
        </div>
        <div class="registrText">
          3.3.3对于本“软件”相关信息等，未经中科可控书面同意，用户不得擅自实施包括但不限于下列行为：使用、复制、修改、链接、转载、汇编、发表、出版，建立镜像站点、擅自借助“软件”发展与之有关的衍生产品、作品、服务等。
        </div>
        <div class="registrText">
          3.3.4用户不得利用本“软件”发表、传送、传播、储存违反国家法律、危害国家安全、祖国统一、社会稳定的内容，或任何不当的、侮辱诽谤的、淫秽的、暴力的及任何违反国家法律法规政策的内容。
        </div>
        <div class="registrText">
          3.3.5用户不得利用本“软件”发表、传送、传播、储存侵害他人知识产权、商业秘密权等合法权利的内容。
        </div>
        <div class="registrText">
          3.3.6用户不得利用本“软件”发布广告信息或披露任何股票、投资、证券等金融信息。
        </div>
        <div class="registrText">
          3.3.7用户不得利用本“软件”误导、欺骗他人，不得用于洗钱、贿赂、赌博等违法行为。
        </div>
        <div class="registrText">
          3.3.8用户不得利用本“软件”传送或散布以其他方式实现传送含有受到知识产权法律保护的图像、相片、软件或其他资料的文件，作为举例（但不限于此），包括版权或商标法（或隐私权或公开权），除非您拥有或控制着相应的权利或已得到所有必要的认可。
        </div>
        <div class="registrText">
          3.3.9用户不得利用本“软件”使用任何包含有通过侵犯商标、版权、专利、商业机密或任何一方的其他专有权利的方式利用本"软件"获得的图像或相片的资料或信息。
        </div>
        <div class="registrText">
          3.3.9用户不得进行任何危害计算机网络安全的行为，包括但不限于：使用未经许可的数据或进入未经许可的服务器/帐户；未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；未经许可，企图探查、扫描、测试本"软件"系统或网络的弱点或其它实施破坏网络安全的行为； 企图干涉、破坏本"软件"系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；伪造TCP/IP数据包名称或部分名称。
        </div>
        <div class="registrText">
          3.4使用本“软件”必须遵守国家有关法律和政策，维护国家利益，保护国家安全，并遵守本《协议》，对于用户违法或违反本《协议》的使用而引起的一切责任，由用户负全部责任，一概与中科可控及合作单位无关，导致中科可控及合作单位损失的，中科可控及合作单位有权要求用户赔偿，并有权立即停止向其提供服务，保留相关记录，保留配合司法机关追究法律责任的权利。
        </div>
        <div class="registrText">
          3.5本“软件”同大多数互联网软件一样，可能受到各种安全问题的侵扰；用户利用本"软件"下载安装的其它软件中含有"特洛伊木马"等病毒，威胁到用户的计算机信息和数据的安全，继而影响本"软件"的正常使用等等。用户应加强信息安全及使用者保护意识，用户对存放在“软件”上的数据及用户名、密码的完整性和保密性负责。因您维护不当或保密不当致使上述数据、信息等丢失或泄露所引起的一切损失和后果均由用户自行承担。
        </div>
        <div class="registrText">
          3.6非经中科可控或中科可控授权开发并正式发布的其它任何由本“软件”衍生的软件均属非法，下载、安装、使用此类软件，将可能导致不可预知的风险，建议用户不要轻易下载、安装、使用，由此产生的一切法律责任与纠纷一概与中科可控无关。中科可控保留向衍生软件开发者追究侵权责任的权利。
        </div>
        <div class="registrText">
          3.7中科可控保留在任何时候根据适用法律、法规、法律程序或政府要求的需要而披露任何信息，或由中科可控自主决定全部或部分地编辑、拒绝张贴或删除任何信息或资料的权利。
        </div>
        <div class="registrText">
          3.8隐私权政策：为了给用户提供更好的服务，当用户使用或者启动“软件”时，服务器会自动记录一些信息，包括URL、IP地址、浏览器的类型和使用的语言以及访问日期和时间等。除了得到用户的授权、或者相关政府部门根据法律法规的规定提出要求、或者用户违反了本《协议》的约定的条款、或者维护中科可控合法权利的情况外，中科可控将不会泄露用户的任何使用信息。
        </div>
        <div class="registrText">
          3.9“软件”的替换、修改和升级：中科可控保留在任何时候通过为您提供本“软件”替换、修改、升级版本的权利以及为替换、修改或升级的权利。同时，用户在此同意，为提高用户体验，中科可控有权将中科可控公司的其他相关产品绑定在本“软件”上供用户进行下载和安装。
        </div>
        <div class="registrText">
          3.10本“软件”可能使用的第三方软件或技术，是受到合法授权的。因第三方软件或技术引发的任何纠纷，由该第三方负责解决，中科可控不承担任何责任。 中科可控不对第三方软件或技术提供客服支持，若您需要获取支持，请与该软件或技术提供商联系。
        </div>
        <div class="registrTextTit">4.法律责任与免责</div>
        <div class="registrText">
          4.1中科可控特别提请用户注意，中科可控为了保障公司业务发展和调整的自主权，中科可控拥有随时自行修改或中断软件授权而不需通知用户的权利，如有必要，修改或中断会以通告形式公布于中科可控网站重要页面上。
        </div>
        <div class="registrText">
          4.2用户违反本《协议》约定的，中科可控可立即发出警告、暂停服务、终止服务、删除数据、提交有关部门、追究法律责任等必要措施。由此造成“服务”中断的风险由您自行承担。
        </div>
        <div class="registrText">
          4.3用户违反本《协议》或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，或基于您与第三方履行合同产生的违约赔偿，包括合理的诉讼费用和律师费用，由此产生全部责任或损失均由您自行承担。并使中科可控、合作公司、关联公司免受损害，若已遭受损失，则您同意向中科可控、合作公司、关联公司全额赔偿。
        </div>
        <div class="registrText">
          4.4若因中科可控违反服务条款规定，软件服务提供方均不对间接的、惩罚性、特殊性、不可预见性、利润损失等损害承担责任。并保留向责任人追索的权利。但任何情况下，中科可控、合作公司、关联公司承担的违约、赔偿责任总额不超过向您收取年服务费总额的30%且以壹万元人民币为上限。
        </div>
        <div class="registrText">
          4.5使用本“软件”由用户自己承担风险，中科可控对本“软件”不作任何类型的担保，不论是明示的、默示的或法令的保证和条件，包括但不限于本“软件”的适销性、适用性、无病毒、无疏忽或无技术瑕疵问题、所有权、平静受益权、和无侵权的明示或默示担保和条件，对在任何情况下因使用或不能使用本“软件”所产生的直接、间接、偶然、特殊及后续的损害及风险，中科可控及合作单位不承担任何责任。
        </div>
        <div class="registrText">
          4.6使用本“软件”涉及到互联网服务，可能会受到各个环节不稳定因素的影响，存在因不可抗力、计算机病毒、黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何网络、技术、通信线路等原因造成的服务中断或不能满足用户要求的风险，用户须明白并自行承担以上风险，中科可控不承担任何责任。
        </div>
        <div class="registrText">
          4.7用户因第三方如电信部门的通讯线路故障、技术问题、网络、电脑故障、系统不稳定性及其他各种不可抗力原因而遭受的经济损失，中科可控不承担任何责任。
        </div>
        <div class="registrText">
          4.8因技术故障、自然灾害等不可抗力事件影响到服务的正常运行的，中科可控承诺在第一时间内与相关单位配合，及时处理进行修复，但用户因此而遭受的经济损失，中科可控不承担任何责任。
        </div>
        <div class="registrTextTit">5.保密条款</div>
        <div class="registrText">
          5.1保密资料指由一方向另一方披露的所有技术及非技术信息（包括但不限于产品资料，产品计划，价格，财务及营销规划，业务战略，客户信息，客户数据，研发，软件硬件，API应用数据接口，技术说明，设计，特殊公式，特殊算法等）。
        </div>
        <div class="registrText">
          5.2本协议任何一方同意对获悉的对方之上述保密资料予以保密，并严格限制接触上述保密信息的员工遵守本条之保密义务。除非国家机关依法强制要求或上述保密资料已经进入公有领域外，接受保密资料的一方不得对外披露。
        </div>
        <div class="registrText">
          5.3本协议双方明确认可各自用户信息和业务数据等是各自的重要资产及重点保密信息。本协议双方同意尽最大的努力保护上述保密信息等不被披露。一旦发现有上述保密信息泄露事件，双方应合作采取一切合理措施避免或者减轻损害后果的产生。
        </div>
        <div class="registrTextTit">6.其他条款</div>
        <div class="registrText">
          6.1本《协议》所定的任何条款的部分或全部无效者，不影响其它条款的效力。
        </div>
        <div class="registrText">
          6.2使用“软件”服务应遵守相关法律、法规的规定，用户自行承担违法行为造成的法律后果。
        </div>
        <div class="registrText">
          6.3本《协议》的解释、效力及纠纷的解决，适用于中华人民共和国法律。若用户和中科可控之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户在此完全同意将纠纷或争议提交中科可控所在地法院管辖。
        </div>
        <div class="registrText">
          6.4根据国家法律法规变化及网站运营需要，中科可控可随时自行全权决定更改《协议》条款，您每次登录时须仔细阅读修订后的《协议》，一旦您选择继续使用本“软件”服务，则视为您已经接受修订后的《协议》。
        </div>
        <div class="registrText">本《协议》的一切解释权与修改权归中科可控</div>
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" size=mini @click="readAgree">已阅读，并同意</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</div>
</template>
<script>
import axios from 'axios'
import { baseHost } from '@/assets/js/PublicData.js'
const defaultCheck = ['1']
export default {
  name: 'Register',
  data () {
    // 用户名验证
    var validateUserName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入用户名'))
      } else {
        if (value !== '') {
          // var reg = /^[a-zA-Z0-9_]{4,16}$/
          var reg = /^(?!\d+$)[\da-zA-Z_]+$/
          if (!reg.test(value)) {
            callback(new Error('用户名必须为 4-16位字符，英文、数字、下划线'))
          } else {
            axios.get(baseHost.SupplierRegHost + '/api/system/user/checkUser', {
              params: {
                type: 1,
                username: value
              }
            }).then((res) => {
              if (res.data.code === '200') {
                this.fistNews = false
                this.sencode = true
              } else {
                this.$message({
                  message: res.data.msg,
                  type: 'error'
                })
              }
            })
          }
        }
        callback()
      }
    }
    // 手机号验证
    var validateMobilePhone = (rule, value, callback) => {
      if (value === '' || value === null) {
        callback(new Error('请输入手机号码'))
      } else {
        if (value !== '') {
          // var reg = /^1[3456789]\d{9}$/
          var regs = value.replace(/\s+/g, '')
          var reg = /^1\s*[34578]\s*(\d\s*){9}$/
          if (!reg.test(regs)) {
            callback(new Error('手机号码错误'))
          } else {
            this.codeDisabled = false
          }
        }
        callback()
      }
    }
    // 邮箱验证
    var validateMobileEmail = (rule, value, callback) => {
      if (value === '' || value === null) {
        callback(new Error('请输入邮箱地址'))
      } else {
        if (value !== '') {
          // var reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
          var reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
          if (!reg.test(value)) {
            callback(new Error('邮箱格式错误'))
          } else {
            this.emailDisabled = false
          }
        }
        callback()
      }
    }
    // 验证密码
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else if (value.length < 6) {
        callback(new Error('密码长度最小6位'))
      } else {
        callback()
      }
    }
    // 验证码验证
    var validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入验证码'))
      } else {
        if (value !== '') {
          var reg = /^\d{4}$/
          if (!reg.test(value)) {
            callback(new Error('验证码必须是4位'))
          }
        }
        callback()
      }
    }
    return {
      cities: defaultCheck,
      JumpTime: '',
      codeTime: '',
      btnTxt: '获取验证码',
      timer: null,
      isStyleregisterName: false,
      isStyleRegisterPass: false,
      isStyleRegisterPhone: false,
      isStyleRegisterMail: false,
      isStyleRegisterCode: false,
      ShowInput1: false,
      ShowInput2: false,
      ShowInput3: false,
      ShowInput4: false,
      clauseVistabe: false,
      codeDisabled: true,
      emailDisabled: true,
      btnDisabled: true,
      passWordRuleForm: {
        username: '',
        password: '',
        verification: '短信验证',
        phone: null,
        mail: null,
        verificationCode: '',
        agreement: []
      },
      passWordNewsRules: {
        username: [
          { required: true, validator: validateUserName, trigger: ['blur'] }
        ],
        password: [
          { required: true, validator: validatePass, trigger: ['blur'] }
        ],
        verification: [
          { required: true, message: '请选择验证方式', trigger: 'change' }
        ],
        phone: [
          { required: true, validator: validateMobilePhone, trigger: ['blur'] }
        ],
        mail: [
          { required: true, validator: validateMobileEmail, trigger: ['blur'] }
          // { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          // { type: 'email', message: '邮箱地址错误', trigger: ['blur'] }
        ],
        verificationCode: [
          { required: true, validator: validateCode, trigger: ['blur'] }
        ]
      }
    }
  },
  methods: {
    // 手机获取验证码
    getCode () {
      axios.get(baseHost.SupplierRegHost + '/api/system/user/sendSmsByNoPhone?phone=' + this.passWordRuleForm.phone).then((res) => {
        if (res.data.code === '200') {
          this.codeTime = 60
          if (this.codeTime > 0) {
            var Cthis = this
            this.codeDisabled = true
            this.btnTxt = this.codeTime + '秒后重新获取'
            const timer = setInterval(function () {
              Cthis.btnTxt = (Cthis.codeTime--) + '秒后重新获取'
              if (Cthis.codeTime <= -1) {
                clearInterval(timer)
                Cthis.btnTxt = '获取验证码'
                Cthis.codeDisabled = false
              }
            }, 1000)
          }
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    // 邮箱获取验证码
    getEmail () {
      axios.get(baseHost.SupplierRegHost + '/api/system/user/sendMailByNoMail?mail=' + this.passWordRuleForm.mail).then((res) => {
        if (res.data.code === '200') {
          this.codeTime = 60
          if (this.codeTime > 0) {
            var Cthis = this
            this.emailDisabled = true
            this.btnTxt = this.codeTime + '秒后重新获取'
            const timer = setInterval(function () {
              Cthis.btnTxt = (Cthis.codeTime--) + '秒后重新获取'
              if (Cthis.codeTime <= -1) {
                clearInterval(timer)
                Cthis.btnTxt = '获取验证码'
                Cthis.emailDisabled = false
              }
            }, 1000)
          }
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    // 注册
    RegisterSubmit (passWordRuleForm) {
      this.$refs[passWordRuleForm].validate((valid) => {
        if (valid) {
          const obj = {
            username: this.passWordRuleForm.username,
            password: this.passWordRuleForm.password,
            phone: this.passWordRuleForm.phone,
            mail: this.passWordRuleForm.mail,
            verificationCode: this.passWordRuleForm.verificationCode
          }
          axios.post(baseHost.SupplierRegHost + '/api/system/user/register ', obj).then((res) => {
            if (res.data.code === '200') {
              this.TimeLogin()
              this.$message({
                message: '注册成功',
                type: 'success',
                offset: 300
              })
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              })
            }
          })
        } else {
          return false
        }
      })
    },
    // 服务条款复选框选中
    GetCheckBok (e) {
      if (e.target.checked === true) {
        this.btnDisabled = false
      } else {
        this.btnDisabled = true
      }
    },
    // 阅读并同意
    readAgree () {
      this.clauseVistabe = false
      this.passWordRuleForm.agreement = ['1']
      this.btnDisabled = false
    },
    // 3秒后跳转登录页
    TimeLogin () {
      this.JumpTime = 2
      if (this.JumpTime > 0) {
        var that = this
        const JumpTimer = setInterval(function () {
          that.JumpTime--
          if (that.JumpTime <= 0) {
            clearInterval(JumpTimer)
            that.$router.push({
              path: '/Login'
            })
          }
        }, 1000)
      }
    },
    // 跳转登录页
    GoLogin () {
      this.$router.push({
        path: '/Login'
      })
    },
    // 用户名获取焦点与失去焦点事件
    NameFocus () {
      this.ShowInput1 = true
      this.isStyleregisterName = true
    },
    NameBlur () {
      this.ShowInput1 = false
      this.isStyleregisterName = false
    },
    // 密码获取焦点与失去焦点事件
    PassFocus () {
      this.ShowInput2 = true
      this.isStyleRegisterPass = true
    },
    PassBlur () {
      this.ShowInput2 = false
      this.isStyleRegisterPass = false
    },
    // 手机号获取焦点与失去焦点事件
    RegFocus () {
      this.ShowInput3 = true
      this.isStyleRegisterPhone = true
    },
    RegBlur () {
      this.ShowInput3 = false
      this.isStyleRegisterPhone = false
    },
    // 邮箱获取焦点与失去焦点事件
    RegMailFocus () {
      this.ShowInput3 = true
      this.isStyleRegisterMail = true
    },
    RegMailBlur () {
      this.ShowInput3 = false
      this.isStyleRegisterMail = false
    },
    // 验证码获取焦点与失去焦点事件
    CodeFocus () {
      this.ShowInput4 = true
      this.isStyleRegisterCode = true
    },
    CodeBlur () {
      this.ShowInput4 = false
      this.isStyleRegisterCode = false
    }
  }
}
</script>
<style scoped lang='scss'>
  @import '@/assets/css/login.scss';
  @import '@/assets/css/promptBox.scss';
  .logo-pic, .logo-tit{
    float: left;
  }
  .logo-pic{
    margin: 14px 15px 0 0;
  }
  .register-logo{
    height: 64px;
    background: #354053;
  }
  .logo-tit{
    margin-top: 14px;
    font-size: 24px;
    color: #fff;
  }
  .bx-box{
    margin: 0 auto;
    width: 1200px;
    height: 64px;
  }
  .register{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
  }
  .clause-box{
    :deep(.el-dialog__body){
      padding: 20px;
    }
    .registrText{
      text-indent: 2rem;
      line-height: 26px;
      margin-bottom: 10px;
    }
    .registrTextTit{
      font-weight: bold;
      margin-bottom: 5px;
    }
    :deep(.el-overlay) {
      justify-content: center;
      align-items: center;
      display: flex;
    }
    :deep(.el-overlay .el-dialog) {
      max-height: calc(100vh - 60px);
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    :deep(.el-dialog__body){
      overflow: auto;
      background: #FBFCFD;
      max-height: calc(100vh - 232px);
    }
    :deep(.el-dialog__header){
      border-bottom: 1px solid #eaeaea;
      padding: 20px;
    }
    :deep(.el-dialog__footer){
      padding: 20px;
      border-top: 1px solid #eaeaea;
    }
  }
  .register-cont{
    width: 460px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border-radius: 5px;
    background: #fff;
  }
  .register-news{
    padding: 0 30px 28px;
  }
  .user-phong{
    position: relative;
    .el-button{
      min-height: 38px;
      border: none;
      min-height: 30px;
      border: none;
      color: #409EFF;
      background: none;
    }
  }
  .register-tit{
    padding: 0 30px;
    line-height: 70px;
  }
  .res-line{
    width: 5px;
    height: 21px;
    background: #409EFF;
    display: inline-block;
    position: absolute;
    left: -15px;
    top: 25px;
  }
  .left-span{
    position: relative;
    float: left;
    margin-left: 14px;
    font-size: 18px;
    color: #666;
  }
  .right-span{
    float: right;
    font-size: 14px;
    color: #409EFF;
    cursor: pointer;
  }
  .right-span span{
    color: #666;
  }
  .yz-flot .yz-ver-left{
    width: 100px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
  .yz-flot{
    position: relative;
  }
  .get-code{
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    padding: 0 10px;
    border-radius: 3px;
    border: none;
    background: none;
  }
  .subbtn{
    width: 100%;
    margin: 20px 0 0;
    font-size: 18px;
  }
  .check-box{
    margin-bottom: 0;
    position: relative;
  }
  .check-span{
    position: absolute;
    left: 22px;
    top: 0;
    cursor: pointer;
    color: #409EFF;
    font-size: 12px;
  }
  .mb-code-pos{
    margin-bottom: 7px;
  }
  .el-checkbox-group{
    margin: 12px 0 8px;
    line-height: 1;
    :deep(.el-checkbox__label){
      display: none;
    }
  }
  .tsinput1{
    width: 340px;
    position: absolute;
    right: -360px;
    top: 0;
    color: #949da1;
    font-size: 12px;
  }
  .tsinputname{
    line-height: 20px;
  }
  .oth-tsinput1{
    right: -660px;
  }
  .login-footer{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 55px;
    text-align: center;
    color: #666;
    font-size: 14px;
    padding-top: 25px;
    background: #eee;
    a{
      color: #007dff;
    }
  }
  .styleName :deep(.el-form-item__error){
    display: none;
  }
  .styleName :deep(.el-input__inner){
    border-color: #F56C6C;
  }
</style>
